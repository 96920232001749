@font-face {
  font-family: GeoramaBold;
  src: url(Font/Georama/static/Georama_SemiCondensed/Georama_SemiCondensed-Bold.ttf);
}

@font-face {
  font-family: GeoramaMedium;
  src: url(Font/Georama/static/Georama_SemiCondensed/Georama_SemiCondensed-Medium.ttf);
}


body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0px;
  padding: 0px;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}


body {
  font-family: GeoramaBold !important;
  /* font-family: 'Georama', sans-serif !important;
  font-weight: bold !important;
  font-stretch: semi-condensed; */
}

.decisionTxt{
  font-family: GeoramaMedium !important;
}

p {
  font-family: GeoramaBold !important;
}

span {
  font-family: GeoramaBold !important;
}

#root {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
}

/*@media only screen and (min-width: 768px) {
  #root {
    max-width: 768px;
  }
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


h1{
  font-size: 6vh;
  margin-top: 0px;
}


/* magnifier logic */

.img-magnifier-container {
  position: relative;
}

.img-magnifier-glass {
  position: absolute;
  border: 3px solid #000;
  border-radius: 50%;
  cursor: none;
  /*Set the size of the magnifier glass:*/
  width: 150px;
  height: 150px;
}