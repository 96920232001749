.App{
  min-height: 100vh;
}

.lang_icon{
  width: 50px;
  cursor: pointer;
  /* placeholder border */
  border: solid 3px rgba(255, 255, 255, 0); 
}


flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}


@keyframes mushroom {
  0% {
    right: 20vh;
    top: 8vh;
    height: 50vh;
    width: 50vh;
  }

  30% {
    right: 5vh;
  }

  100% {
    right: 5vh;
    top: 5vh;
    height: 0vh;
    width: 0vh;
  }
}

@keyframes layerIn {
  0% {
    right: -102.43vh;
  }

  100% {
    right: 0vh;
  }
}

@keyframes layerOut {
  0% {
    right: 0vh;
  }

  100% {
    right: -102.43vh;
  }
}

@keyframes mushroomIn {
  0% {
    right: -50vh;
  }

  100% {
    right: 20vh;
  }
}

@keyframes mushroomDismiss {
  0% {
    right: 20vh;
  }

  100% {
    right: -50vh;
  }
}

@keyframes slide1 {
  0%{
    background-position-x: 0px;
  }
 
 100% {
  background-position-x: -600px;
  }
 }

 @keyframes slide2 {
  0%{
    background-position-x: 0px;
  }
 
 100% {
  background-position-x: -500px;
  }
 }
 @keyframes slide3 {
  0%{
   background-position-x: 0px;
  }
 
 100% {
  background-position-x: -400px;
  }
 }
 @keyframes slide4 {
  0%{
    background-position-x: 0px;
  }
 
 100% {
  background-position-x: -300px;
  }
 }



 @keyframes slide1Out {
  0%{
    background-position-x: -600px;
  }
 
 100% {
  background-position-x: 0px;
  }
 }

 @keyframes slide2Out {
  0%{
    background-position-x: -500px;
  }
 
 100% {
  background-position-x: 0px;
  }
 }
 @keyframes slide3Out {
  0%{
   background-position-x: -400px;
  }
 
 100% {
  background-position-x: 0px;
  }
 }
 @keyframes slide4Out {
  0%{
    background-position-x: -300px;
  }
 
 100% {
  background-position-x: 0px;
  }
 }