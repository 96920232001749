.MuiRadio-colorSecondary.Mui-checked {
    color: grey !important;
}
.MuiRadio-root {
    color: grey !important;
}

.MuiSelect-icon, .MuiFormLabel-root, .MuiSelect-root {
    color: grey !important;
}
.MuiSelect-nativeInput{
    color: grey !important;
}
.MuiList-root{
    background-color: white;
    color: grey !important;
}

.MuiInputBase-root{
    border: 0.5px solid grey;
    color: grey !important;
}